.paper {
  width: 320px;
  margin: 0 auto;

  & :global(.MuiPaper-root) {
    padding: 0;
  }

  & :global(.MuiDialogActions-root) {
    padding: 10px 20px;
  }
}

div.dialogContainer {
  height: 100%;
}

div.paperContainer {
  @media (max-width: 960px) {
    height: auto;
    :global(.MuiDialogContent-root) {
      margin-bottom: 0;
    }
  }
}

.input {
  & :global(.MuiInputBase-root) {
    border-radius: 8px;
  }

  & :global(.MuiInputBase-input.MuiOutlinedInput-input) {
    padding: 10;
  }

  & :global(.MuiInputLabel-root.MuiInputLabel-outlined) {
    margin-top: -5px;
  }
}

.openPickerBtn {
  cursor: pointer;
  color: #691b33;

  &:hover {
    color: #bc6f87;
  }
}

.desktopPaper {
  box-shadow: none;
  border-radius: 6px;
  border: 1px solid #691b33;
}

div.layout {
  :global(.MuiPickersCalendarHeader-root) {
    margin-top: 8px;
    padding-left: 12px;
    padding-right: 0;
  }

  :global(.MuiDayCalendar-header) {
    border-bottom: 1px solid #ebe4e4;
    margin: 0 12px;
    justify-content: space-between;

    & > * {
      height: 30px;
      width: 12px;
    }
  }

  :global(.MuiDateCalendar-root) {
    width: 272px;

    @media (max-width: 960px) {
      width: auto;
      margin: 0 20px;
    }
  }
  :global(.MuiPickersDay-daySelected){
    background-color: transparent;
    color: inherit;

    &:hover {
      background-color: transparent;
    }
  }
  @media (max-width: 960px) {
    display: block;

    :global(.MuiPickersToolbar-root) {
      display: none;
    }
  }
}
