.budge {
  color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ff5c32;
  margin-left: 5px;
  text-align: center;
  line-height: 24px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.15px;
}
