.tabs {
  display: flex;
  min-height: 48px;

}

.tab {
  text-transform: none;
  min-width: 0;
  font-size: 16px;
  font-weight: 400;
  margin-right: 24px;
  color: #691b33;
  padding: 0;
  border: none;
  background-color: transparent;
  position: relative;

  &:hover {
    color: #bc6f87;
    opacity: 1;
    cursor: pointer;
  }

  &.disabled {
    color: #999a9a;
  }

  &.selected {
    color: #333333;
    font-weight: 500;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -8px;
      height: 4px;
      background-color: #691B33;
      width: 100%;
      @media (max-width: 720px) {
        bottom: 0;
      }
    }

    @media (max-width: 720px) {
      font-weight: 400;
    }
  }

  &:focus-visible {
    background-color: #d1eaff;
  }

  @media (max-width: 720px) {
    font-size: 14px;
    font-weight: 400;
  }
}