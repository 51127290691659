@import "@layout/z-index-layouts.scss";

.suggestedCities {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #691b33;

  & :not(:last-of-type) {
    margin-right: 10px;
  }

  & :hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.popper {
  z-index: $zindex-autocomplete;
}

li.optionItem {
  word-wrap: break-word;
  font-weight: 300;
  display: flex;

  &:global(.MuiAutocomplete-option) {
    align-items: baseline;
    flex-wrap: wrap;
  }
}

.itemBoldText {
  font-weight: 700;
  display: block;
}

.option {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  width: 100%;
}

.warning {
  color: #691b33;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
}

.listbox {
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #b38d99 transparent;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #b38d99;
  }
}
