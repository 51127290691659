.btn {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: center;
  border: none;
  color: #fff;
  background-color: #5f6eb2;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0;
  padding: 15px 44px;
  height: 44px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.1s;

  &:hover:not(:disabled) {
    background-color: #7983b0;
  }

  &:active:not(:disabled) {
    background-color: #404e90;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.fullWidth {
  width: 100%;
}

.transparentBackground {
  background-color: transparent;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background-color: transparent;
  }
}
