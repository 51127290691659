.container {
  position: relative;
  margin-bottom: 16px;
  width: 100%;
}

.container label {
  position: absolute;
  top: 10px;
  left: 15px;
  color: #8f8f8f;
  transition: 0.15s ease-in-out;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.container input {
  width: 100%;
  height: 44px;
  letter-spacing: 1px;
}

.container .inputField {
  font-size: 1em;
  color: #333;
  padding-left: 15px;
  border: 1px solid #ebe4e4;
  border-radius: 8px;
  outline: none;
}

.inputField:focus ~ label,
.inputField[value]:not([value=""]) ~ label {
  top: -7px;
  left: 12px;
  color: #8a8a8a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  background: #fff;
  padding: 0 5px;
}