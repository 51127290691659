.title {
  flex: 1;
  padding: 12px 12px 0 12px;
  margin: 0;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;

  @media (max-width: 720px) {
    font-size: 13px;
    padding: 6px 6px 0 6px;
    max-height: 42px;
    overflow: hidden;
  }
}

.mobileRootContainer {
  flex: 1;
}

.attributes {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 12px 12px 8px 12px;
  color: #333;
  font-size: 13px;
  line-height: 140%;
  font-weight: 400;

  @media (max-width: 720px) {
    font-size: 12px;
    padding: 6px 6px 0 6px;
    flex: 1;
    align-items: flex-start;

    .mobileAttributesList {
      display: flex;
      align-items: center;
      max-width: 100%;

      &:first-of-type {
        margin-bottom: 4px;
      }
    }

    .mobileAttribute {
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      color: #333;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      & svg {
        color: #691b33;
      }
    }

    .attributeIcon {
      display: inline-block;
      vertical-align: text-bottom;
    }
  }

  .attributesList {
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  .attributesList:first-of-type {
    margin-bottom: 4px;
  }

  .attribute {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    & svg {
      color: #691b33;
    }
  }
}

.country {
  color: #333;
  font-size: 13px;
  line-height: 140%;
  font-weight: 400;
  padding: 0 12px;
  display: flex;
  align-items: center;
}
