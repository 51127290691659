.stockStatus {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #999;
  font-size: 13px;
  font-weight: 500;
  line-height: 140%;
  padding: 12px 12px 0 12px;
  gap: 4px;

  @media (max-width: 720px) {
    font-size: 12px;
    padding: 6px 6px 0 6px;
  }

  & > :first-of-type {
    margin-right: 4px;
  }

  &:global(.instock) {
    color: #008000;
  }

  &:global(.limited) {
    color: #691b33;
  }

  &:global(.soldout) {
    stroke: #999;
  }

  &:global(.preorder) {
    color: #5f6eb2;
  }
}
