.container {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.chooseFiles {
  width: 100%;
  border: dashed 1.5px #691B33;
  padding: 16px 20px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: transparent;
  cursor: pointer;
}

.addMoreImages {
  padding: 16px 10px;
  flex-direction: column;
  max-width: 87px;
  height: 87px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  gap: 0;
  margin: 0;
  cursor: pointer;
}

.imageContainer {
  position: relative;
}

.image {
  border-radius: 8px;
  height: 87px;
  width: 87px;
  object-fit: cover;
}

.deleteBtn {
  position: absolute;
  top: 5px;
  right: 8px;
  background-color: #CDCECE;
  border-radius: 50%;
  padding: 4px;
  border: none;
  cursor: pointer;
}

.inputField {
  display: none;
}

.description {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  margin: 0 0 12px 0;
}

.icon {
  & > * {
    fill: #333;
  }
}

