.customPagination {
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  & > :global(.swiper-pagination-bullet) {
    background-color: #ebe4e4;
    width: 7px;
    height: 7px;
    opacity: 1;
  }

  & > :global(.swiper-pagination-bullet-active) {
    background-color: #691b33;
    width: 10px;
    height: 10px;
  }
}