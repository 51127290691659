@import "@layout/z-index-layouts.scss";

.wrapperIcon {
  margin-right: auto;
  padding: 12px 12px 0 12px;

  & > *:hover {
    color: #bc6f87;
    cursor: pointer;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  margin: 0;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: $zindex-modal;

  @media (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
}

.modalContainer {
  width: 460px;
  pointer-events: auto;
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 0 auto;

  @media (max-width: 720px) {
    width: 100%;
    height: 100%;
  }
}

.container {
  padding: 0 40px 40px;

  @media (max-width: 720px) {
    padding: 0 12px;
  }
}

div.withOutPadding {
  padding: 12px 0 40px 0;
}
