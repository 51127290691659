.wrapper {
  padding: 24px 12px 10px 32px;
  background-color: #fff;
  max-width: 440px;
  border-radius: 12px 0 0 12px;
  overflow: hidden;
}

.selectedCity {
  padding-right: 18px;
  max-height: 383px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #999 transparent;

  &::-webkit-scrollbar {
    width: 2px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #999;
    padding-left: 10px;
  }

  &::-webkit-scrollbar:active {
    background-color: #691b33;
  }
}

.wrapperSelectedCity {
  border: 1px solid transparent;
  padding: 16px;
  max-width: 380px;

  .img {
    height: 200px;
    width: 380px;
    border-radius: 8px;
    margin-bottom: 14px;
    object-fit: cover;
  }

  .typeBuilding {
    margin: 0 0 8px 0;
    color: #ff5c32;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  .street {
    margin: 0 0 8px 0;
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: 0.2px;
  }

  .infoWrapper {
    margin: 0 0 8px 0;
    flex-wrap: wrap;
    display: flex;
    .phone {
      margin: 0 16px 0 0;
      color: #333;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    .workHours {
      color: #999;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      display: flex;
      gap: 16px;
      margin: 0;
    }
  }

  .getWay {
    margin: 0;
    color: #691b33;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    text-decoration-line: underline;
    text-decoration-skip-ink: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.disActive {
  border: 1px solid blue;
}
.active {
  border: 1px solid red;
  border-radius: 8px;
  padding-bottom: 16px;
}

.link {
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  color: #691B33;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  border-bottom: 1px solid #691B33;

  &:hover {
    color: #BC6F87;
    border-color: #BC6F87;
  }
}