.dialogTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #333333;
  margin-bottom: 12px;
  text-align: center;
  margin: 0 0 25px;
}

.quickBuyBtn {
  cursor: pointer;
  color: #691b33;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  border-bottom: 1px solid #691b33;

  &:hover {
    color: #bc6f87;
    border-color: #bc6f87;
  }
}

.name {
  margin-bottom: 16px;
}

.successMessage {
  text-align: center;
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 20px 0 0 0;
}

.error {
  margin-top: 10px;
}
.orderId{
  margin: 8px 0 0 0;
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}