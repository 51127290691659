.wrapper {
  font-size: 12px;
  line-height: 1.2;

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    .separator {
      margin: 0 8px;
    }
  }

  .breadcrumb {
    & > * {
      display: inline-block;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:last-of-type {
      color: #691b33;
      cursor: default;
    }

    .link {
      color: #333;
      cursor: pointer;

      &:hover {
        color: #bc6f87;
      }
    }
  }
}
