@import '@layout/z-index-layouts.scss';

.watchIcon {
  position: absolute;
  top: 22px;
  right: 22px;
  z-index: $zindex-product-card-icon;

  @media (max-width: 720px) {
    top: 10px;
    right: 10px;
  }

  &:hover svg,
  &:hover path {
    stroke: #bc6f87;
  }

  &:active svg,
  &:active path {
    stroke: #450f20;
    fill: #450f20;
  }

  &.disabled {
    &:hover svg,
    &:hover path {
      stroke: none;
    }

    &:active svg,
    &:active path {
      stroke: #aaa;
      fill: white;
    }
  }

  &.watchIconSelected svg,
  &.watchIconSelected path {
    stroke: #691b33;
    fill: #691b33;
  }
}
