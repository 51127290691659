.buyButton {
  padding: 0;
  border-radius: 50px;
  background-color: #5f6eb2;
  width: 50px;
  height: 50px;
  margin-right: 12px;
  line-height: 24px;
  box-sizing: border-box;

  & > * {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 720px) {
    width: 40px;
    height: 40px;
    margin-right: 6px;
    line-height: 20px;

    & > * {
      width: 20px;
      height: 20px;
    }
  }

  svg {
    stroke: initial;
  }

  &:active {
    outline: none;
    background: none;
  }

  &:hover {
    background-color: #7983b0;
  }

  &:active {
    background-color: #404e90;
  }

  &:disabled {
    background-color: #5f6eb2;
  }

  &.buyButtonInCart {
    background-color: #fff;
    border: 1px solid #5f6eb2;

    &:active {
      outline: none;
      background: none;
    }

    &:hover {
      border-color: #7982b0;
      opacity: 0.9;
    }

    &:active {
      border-color: #414e91;
      opacity: 1;
    }

    &:disabled {
      border-color: #9ea6c9;
    }
  }
}