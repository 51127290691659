.otpContainer {
  display: flex;
  justify-content: space-between;
}

.otpInput {
  padding: 10px 1px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #e7e0e0;
  display: flex;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &Error {
    border: 1px solid red;
  }
}

.errorText {
  color: #f00;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 8px 0 0;
  text-align: center;
}
