.button {
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  color: #691b33;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  border-bottom: 1px solid #691b33;

  &:hover {
    color: #bc6f87;
    border-color: #bc6f87;
  }

  &:disabled {
    color: #d8d8d8;
    border-color: #d8d8d8;
    cursor: not-allowed;
  }
}
