.highlightIcon {
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 8px 12px;
  border-radius: 30px;
  background-color: #691b33;
  z-index: 2;
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  @media (max-width: 720px) {
    left: 4px;
    top: 10px
  }
}