.button {
  background: none;
  border: none;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      stroke: #BC6F87;
    }
  }

  svg {
    stroke: #333333;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }
}