@import "@layout/z-index-layouts.scss";

$spacing-unit: 8px; // Define your theme's spacing unit here
$common-black: #000; // Define your theme's common black color here
$text-secondary: #757575; // Define your theme's text secondary color here
$common-disabledBlackBackground: #ebebeb; // Define your theme's disabled black background color here
$text-disabled: #bdbdbd; // Define your theme's disabled text color here

.root {
  margin-bottom: 10px;
}

.dropdownIndicator {
  transform-origin: center;
  transition: transform ease-in-out 0.2s;
}

.dropdownIndicatorActive {
  transform: rotate(180deg);
}

.valueContainer {
  display: flex;
  flex: 1;
  align-items: flex-end;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  & input {
    line-height: 22px;
  }
}

.noOptionsMessage {
  padding: $spacing-unit * 2;
  margin: 0;
}

.singleValue {
  margin-bottom: 0;
  font-size: 16px;
  white-space: nowrap;
  color: $common-black;
}

.menuItem {
  padding: 11px 12px;
  margin-bottom: 0;
  position: relative;

  &:hover {
    background-color: #f4eae9;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $common-disabledBlackBackground;
  }

  & .main-label {
    color: $text-secondary;
  }

  & > :hover {
    background-color: #f4eae9;

    & .main-label {
      color: $common-black;
    }
  }

  & > * > :active {
    &:before {
      width: 100%;
      left: 0;
      background-color: transparent;
    }
  }
}

.menuItemDisabled {
  &:hover {
    cursor: not-allowed;
    font-weight: normal;

    & .main-label {
      color: $text-disabled;
      font-weight: normal;
    }
  }
}

.menuItemSelected {
  background-color: rgba(51, 51, 51, 0.05);

  &:hover {
    background-color: #f4eae9;
  }

  & > * {
    & .main-label {
      color: $common-black;
    }

    &:before {
      width: 100%;
      left: 0;
    }
  }
}

.menuItemMain {
  flex: 1;
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.paper {
  position: absolute;
  z-index: $zindex-autocomplete;
  margin-top: $spacing-unit * 0;
  left: 0;
  right: 0;
  border: 1px solid #691b33;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  overflow: hidden;
  box-shadow: none;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  color: #333;

  & > div {
    padding: 0;
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.25) transparent;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
}

.controlErrorMessage {
  position: absolute;
  z-index: 1;
  bottom: -18px;
  left: 16px;
  color: #9a9a9a;
  font-size: 14px;
}

.controlContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-style: solid;
  border-color: transparent;
  border-width: 1px;
  border-bottom: transparent;

  .controlInner {
    display: flex;
    padding: 11px 11px;
    line-height: 22px;
  }

  &.controlError:not(.controlActive) {
    border-color: #f44336;

    .controlLabel {
      color: #f44336;
    }

    .dropdownIndicator {
      color: #f44336;
    }
  }

  .floating {
    top: -6px;
    left: 10px;
    font-size: 12px;
    color: #9a9a9a;
    line-height: 12px;
  }
}

.controlLabel {
  position: absolute;
  left: 12px;
  top: 12px;
  color: #9a9a9a;
  background-color: white;
  padding: 0 4px;
  transition: all 0.2s ease-out;
  pointer-events: none; // Ensures click events pass through to the input
  line-height: 22px;
}

.controlOutline {
  background-color: #fff;
  border-radius: 8px;
  border-bottom: solid;
  border-color: #9a9a9a;
  border-width: 1px;
}

.controlActive {
  background-color: #fff;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: transparent;
  border-color: #691b33;
  border-width: 1px;

  &.controlOutline {
    border-bottom: transparent;
    padding-bottom: 1px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .floating {
    color: #9a9a9a;
    line-height: 12px;
  }

  & .controlLabel {
    top: -6px;
    left: 10px;
    font-size: 12px;
    color: #691b33;
    line-height: 12px;
  }
}
