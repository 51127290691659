.wrapper {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #EBE4E4;
}

.container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.info {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 12px;
}

.priceWithDiscount {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.totalPrice {
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.imgProduct {
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
}

.prices {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &.postcardContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .postcardMessage {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    max-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
    width: calc(100% - 22px);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    min-width: fit-content;

    &.showFullPostcard {
      max-height: 100%;
      display: block;
    }
  }
}

.productQty {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
}

.name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #333333;
  margin: 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.productCode {
  margin: 0;
  color: #9a999a;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  &.containerProductCode {
    margin: 0 0 12px 0;
  }
}

.stockStatus {
  margin: 0 0 12px 0;
  padding-left: 0;
}

.feedbackLabel {
  background: transparent;
  border: none;
  border-bottom: 1px solid #691b33;
  padding: 0;
}

.subtitleContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 0 0 16px 0;
}

.img {
  border-radius: 4px;
}

.postcardWrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  width: 100%;
}

.arrow {
  & > svg > path {
    fill: #691b33;
  }

  cursor: pointer;
  transition: transform 0.3s ease;

  &.arrowRotate {
    transform: rotate(180deg);
    vertical-align: top;
  }
}
