/*
  if you need to add something between these numbers,
  just add new one and increase the numbers below the number you just added by +1
*/

$zindex-regular-icon: 1;
$zindex-regular-tooltip: 1;
$zindex-product-card-icon: 2;
$zindex-gallery-icon: 2;
$zindex-mobile-filters-actions: 2;
$zindex-sorting-bar: 3;
$zindex-modal-more: 4;
$zindex-drawer-catalog: 4;
$zindex-mobile-footer-nav: 5;
$zindex-header: 6;
$zindex-modal: 7;
$zindex-drawer: 7;
$zindex-autocomplete: 7;
