.inputBox {
  position: relative;
  margin-bottom: 16px;
}

.inputBox label {
  position: absolute;
  top: 10px;
  left: 15px;
  color: #8f8f8f;
  transition: 0.15s ease-in-out;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.inputBox input {
  width: 100%;
  height: 44px;
  letter-spacing: 1px;
}

.inputBox .inputField {
  font-size: 1em;
  color: #333;
  padding-left: 15px;
  border: 1px solid #ebe4e4;
  border-radius: 8px;
  outline: none;
}

.inputField:focus ~ label,
.inputField:not([value=""]) ~ label {
  top: -7px;
  left: 12px;
  color: #8a8a8a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  background: #fff;
  padding: 0 5px;
}

// * make eye icon visible when input is not empty
.inputField:not([value=""]) ~ * > .passwordToggleIcon {
  opacity: 1;
}

.error {
  margin: 0;
  color: #141415;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding: 3px 0 0 13px;
}

.passwordToggle {
  position: absolute;
  top: 25%;
  left: 90%;
}

.passwordToggleIcon {
  // * eye icon is invisible by default
  opacity: 0;
  cursor: pointer;
  stroke-width: 2px;
  transition: opacity 0.1s;

  &:hover {
    opacity: 1;
  }
}

.inputBox input[type="password"] {
  letter-spacing: 2px;
}
