.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 8px;
}

.itemTotal {
  margin-top: 16px;
}

.label {
  color: #333;
}

.discount {
  color: #FF5C32;
}

.total {
  color: #333;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}