.price {
  .priceWithoutDiscount {
    text-decoration: line-through;
    color: #989898;
    font-size: 16px;
    line-height: 120%;
    font-weight: 500;
    margin-right: 12px;

    @media (max-width: 720px) {
      font-size: 12px;
      margin-right: 6px;

      &.largePrice {
        font-size: 16px;
      }
    }
  }

  .priceWithDiscount {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #ff5c32;

    @media (max-width: 720px) {
      font-size: 20px;

      &.largePrice {
        font-size: 24px;
      }
    }
  }

  .regularPrice {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;

    @media (max-width: 720px) {
      font-size: 20px;

      &.largePrice {
        font-size: 24px;
      }
    }
  }
}
