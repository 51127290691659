.container {
  display: flex;
  height: 34px;
  align-items: center;
  cursor: pointer;
  margin-left: -5px;

  .disabled {
    cursor: not-allowed;
  }
}

.container {
  & label {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.container input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  height: 24px;
  width: 24px;
}

.container input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.label {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  &.disabled {
    user-select: none;
    color: #ccc;
    .icon {
      & > * {
        color: #ccc;
      }
    }

    &:hover {
      .icon {
        & > * {
          fill: #fff;
          color: #ccc;
        }
      }
    }

    &:active {
      .icon {
        color: #ccc;
        background-color: transparent;
      }
    }
  }

  &:hover {
    .icon {
      & > * {
        fill: #691b33;
        color: #bc6f87;
      }
    }

    .checkboxChecked {
      & > * {
        color: #450f20;
        fill: #450f20;
      }
    }
  }

  &:active {
    .icon {
      background-color: #f4eae9;
      color: #450f20;
      border-radius: 50%;
    }
  }
}

div.icon {
  padding: 3px;

  & > * {
    fill: #691b33;
    color: #e1c0ca;
  }

  &.checkboxChecked {
    & > * {
      color: #691b33;
    }
  }
}
