.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

.switch {
  position: relative;
  display: inline-flex;
  width: 34px;
  height: 14px;
  margin-right: 8px;
}

.switch .input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d7d7d7;
  transition: 150ms ease-in-out;
  border-radius: 14px;
  &:hover {
    background-color: #dadada;
  }
  &.disabled {
    cursor: not-allowed;
    background-color: #efefef;
    &:before {
      background-color: #d5d5d5;
    }
  }
  @media (min-width: 720px) {
    cursor: pointer;
  }
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: -4px;
  bottom: -3px;
  background-color: #989898;
  transition: 150ms ease-in-out;
  border-radius: 50%;
  &:hover {
    background-color: #808080;
  }
}

.input:checked + .slider {
  background-color: #b48d99;

  &:hover {
    @media (min-width: 720px) {
      background-color: #f1dbe3;
    }
  }
}

.input:checked + .slider:before {
  transform: translateX(20px);
  background-color: #691b33;
}

.input:checked + .slider:hover:before {
  @media (min-width: 720px) {
    background-color: #d4a8b5;
  }
}

.input + .slider:hover:before {
  @media (min-width: 720px) {
    background-color: #9f9f9f;
  }
}
