.wrapper {
  margin-bottom: 20px;
}

div.container {
  & :global(.flag-dropdown) {
    border-radius: 8px 0 0 8px;
    border: 1px solid #ebe4e4;
    border-right: none;
    padding: 0 6px;
    background-color: #fff;
  }

  & :global(.selected-flag) {
    border-radius: 8px 0 0 8px;

    &:hover {
      background-color: transparent;
    }
  }

  &Error {
    & :global(.flag-dropdown) {
      background-color: #faf0f0;
    }
  }
}

div.containerDisabled {
  & :global(.flag-dropdown) {
    background-color: #eee;
  }
}

input.input {
  &:global(.form-control) {
    border-radius: 8px;
    border: 1px solid #ebe4e4;
    width: 100%;
    height: 44px;
    padding-left: 52px;
  }

  &:disabled {
    background-color: #eee;
  }
}

.error {
  color: red;
}
