.textAreaBox {
  position: relative;
  margin-bottom: 16px;
}

.textAreaBox label {
  position: absolute;
  top: 10px;
  left: 15px;
  color: #8f8f8f;
  transition: 0.15s ease-in-out;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.textAreaBox textarea {
  width: 100%;
  max-width: 380px;
  letter-spacing: 1px;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #b38d99 transparent;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #b38d99;
  }
}

.textAreaBox .textAreaField {
  font-size: 1em;
  color: #333;
  padding-top: 10px;
  padding-left: 15px;
  border: 1px solid #ebe4e4;
  border-radius: 8px;
  outline: none;
}

.textAreaField {
  resize: none;
}

.textAreaField:focus ~ label,
.textAreaField:not(:empty) ~ label {
  top: -9px;
  left: 12px;
  color: #8a8a8a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  background: #fff;
  padding: 0 5px;
}

.error {
  margin: 0;
  color: #141415;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  padding: 3px 0 0 13px;
}
