.socialIconButton {
  padding: 4px;
  border-radius: 50%;
  border-bottom: none;
  fill: #691B33;
  border: 1px solid transparent;

  &:hover {
    fill: #BC6F87;
    border-color: transparent;
  }

  &:active {
    color: #FF5C32;
    fill: #FF5C32;
    border-color: transparent;
  }
}

.socialIconButtonDark {
  color: #ffffff;

  &:hover {
    color: #cddc39;
  }

  &:active {
    color: #cddc39;
    background-color: #FF5C32;
  }
}

.wrapper {
  display: flex;
}