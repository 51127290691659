@import "@layout/z-index-layouts.scss";

$animation-duration: 225ms;
$transform-bezier: cubic-bezier(0, 0, 0.2, 1);
$opacity-bezier: cubic-bezier(0.4, 0, 0.2, 1);

.wrapperIcon {
  padding: 12px 12px 0 12px;

  & > *:hover {
    color: #bc6f87;
    cursor: pointer;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: $zindex-drawer;
  opacity: 0;
  transition: opacity $animation-duration $opacity-bezier;
  pointer-events: none;

  &.withoutBackdrop {
    background-color: transparent;
  }

  &.drawerZIndex {
    z-index: $zindex-drawer-catalog;
  }

  &.open {
    opacity: 1;
    pointer-events: auto;
  }

  &.close {
    opacity: 0;
    pointer-events: none;
  }
}

.modalContainer {
  color: #333;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 6px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: fixed;
  outline: 0;
  max-height: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  transition: transform $animation-duration $transform-bezier;

  &.anchor-left {
    top: 0;
    left: 0;
    height: 100%;
    width: auto;
    transform: translateX(-100%);

    &.open {
      transform: translateX(0);
    }

    &.close {
      transform: translateX(-100%);
    }
  }

  &.anchor-right {
    top: 0;
    right: 0;
    height: 100%;
    width: auto;
    transform: translateX(100%);

    &.open {
      transform: translateX(0);
    }

    &.close {
      transform: translateX(100%);
    }
  }

  &.anchor-top {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    transform: translateY(-100%);

    &.open {
      transform: translateY(0);
    }

    &.close {
      transform: translateY(-100%);
    }
  }

  &.anchor-bottom {
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);

    &.open {
      transform: translateY(0);
    }

    &.close {
      transform: translateY(100%);
    }
  }
}
