.optionItem {
  word-wrap: break-word;
  font-weight: 300;

  &:global(.MuiAutocomplete-option) {
    display: block;
  }
}

.itemBoldText {
  font-weight: 700;
  &:not(:first-of-type) {
    margin-left: 4px;
  }
}

.listbox {
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #b38d99 transparent;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #b38d99;
  }
}