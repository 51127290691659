.root {
  width: 100%;

  .divider {
    margin-left: 24px;
    margin-right: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  .panel {
    border: none;
    box-shadow: none;
    background: none;
  }

  .leftIcon {
    margin-left: 0;
    margin-right: 0;
    padding: 8px;
  }

  .smallIcon {
    width: 12px;
    height: 17px;
  }

  .details {
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  .detailsExpanded {
    max-height: max-content;
  }

  .heading {
    display: flex;
    align-items: center;
    min-height: 30px;
    font-size: 14px;
    font-weight: 400;
  }
}

.arrow {
  transition: transform 150ms ease-in-out;
  transform-origin: center;
}

.arrowExpand {
  transform: rotate(180deg);
}

.labelContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: 720px) {
    cursor: pointer;
  }
}
