.wrapper {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 24px;
}

.star {
  cursor: pointer;
}
