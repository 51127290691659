@import "@layout/z-index-layouts.scss";

.mobileModal {
  width: 100vw;
  padding: 16px 16px 24px;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  position: absolute;
  z-index: $zindex-modal;
  @media (min-width: 720px) {
    display: none;
  }
}

.drawerContainer {
  height: calc(400px + env(safe-area-inset-bottom));
}

.closeMark {
  text-align: right;
  padding: 0 0 16px 0;

  & svg:hover {
    color: #bc6f87;
    cursor: pointer;
  }
}

.wrapperSelectedCity {
  .img {
    height: 200px;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 14px;
    object-fit: cover;
  }

  .typeBuilding {
    margin: 0 0 8px 0;
    color: #ff5c32;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  .street {
    margin: 0 0 8px 0;
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: 0.2px;
  }

  .infoWrapper {
    margin: 0 0 8px 0;
    display: flex;

    .phone {
      margin: 0 16px 0 0;
      color: #333;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    .workHours {
      color: #999;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      display: flex;
      gap: 16px;
    }
  }

  .getWay {
    margin: 0;
    color: #691b33;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    text-decoration-line: underline;
    text-decoration-skip-ink: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.link {
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  color: #691b33;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  border-bottom: 1px solid #691b33;

  &:hover {
    color: #bc6f87;
    border-color: #bc6f87;
  }
}
