.mapContainer {
  width: 100%;
  height: 100%;
  border-radius: 0 12px 12px 0;
  @media (max-width: 720px) {
    height: 350px;
    border-radius: 12px 0 0 12px;
  }
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}