@import '@layout/z-index-layouts.scss';

.discountIcon {
  position: absolute;
  top: 20px;
  left: 20px;
  background: #ffffff;
  border-radius: 100px;
  width: 43px;
  height: 43px;
  border: 1px solid #691b33;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $zindex-product-card-icon;

  .priceDiscount {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
  }

  @media (max-width: 720px) {
    top: 10px;
    left: 10px;
  }

  & > * {
    font-weight: 500;
    line-height: 100%;
    color: #691b33;
  }
}